<template>
    <div>
        <sc-modal @close="$emit('close')" title="Operational Message details">
            <div class="details-row">
                <div class="details-key">Title</div>
                <div class="details-value">{{ item.attributes.title }}</div>
            </div>
            <div class="details-row">
                <div class="details-key">Text</div>
                <div class="details-value">{{ item.attributes.text_1 }}</div>
            </div>

            <div class="details-row" v-if="item.created_by">
                <div class="details-key">Created by</div>
                <div class="details-value" v-if="item.created_by">{{ item.created_by.data.attributes.first_name }}
                    {{ item.created_by.data.attributes.last_name }} ({{ item.created_by.data.attributes.email }})
                </div>
                <div class="details-value" v-else><i>Deleted User</i></div>
            </div>
            <div class="details-row">
                <div class="details-key">Created at</div>
                <div class="details-value">{{ item.attributes['created-at'] | toDatetime }}</div>
            </div>
            <div class="details-row">
                <div class="details-key">Updated at</div>
                <div class="details-value">{{ item.attributes['updated-at'] | toDatetime }}</div>
            </div>
            <div class="details-row">
                <div class="details-key">Players</div>
                <div class="details-value players">
                    <ul v-if="item.players">
                        <li v-for="p in item.players.data" :key="`player-${p.id}`">{{ p.attributes.name }}</li>
                    </ul>
                    <span v-else>None</span>
                </div>
            </div>
            <div class="details-row">
                <div class="details-key">Players Groups</div>
                <div class="details-value">
                    <table v-if="item.player_groups">
                        <thead>
                        <tr>
                            <th>Group</th>
                            <th>Players</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="group in item.player_groups.data" :key="`group-${group.id}`">
                            <td>
                                {{ group.attributes.name }}
                            </td>
                            <td>
                                <ul v-if="group.players">
                                    <li v-for="p in group.players.data" :key="`group-player-${p.id}`">
                                        {{ p.attributes.name }}
                                    </li>
                                </ul>
                                <span v-else>None</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <span v-else>None</span>
                </div>
            </div>
        </sc-modal>

    </div>
</template>

<script>
export default {
  name: "template-details",

  props: {
    item: {}
  }
}
</script>

<style scoped lang="scss">

.details-row {

    padding: .5em .75em;
    border: 1px solid #e2e2e2;

    &:nth-child(1n+2) {
        border-top: none;
    }

    div {
        display: inline-block;
    }

    i {
        font-style: italic;
    }

    .details-key {
        width: 140px;
    }

    .details-value {
        vertical-align: top;
        box-sizing: border-box;

        &.players {
            font-size: .8em;
        }

        ul {
            box-sizing: border-box;

            li {
                list-style: circle;
                margin-left: 1.5em;
                box-sizing: border-box;
            }
        }

        table {
            box-sizing: border-box;
            font-size: .8em;


            th, td {
                font-weight: bold;
                overflow: hidden;
                padding: .25em .5em;
                border: 1px solid #e2e2e2;
                box-sizing: border-box;
            }

            td {
                font-weight: normal;
                min-width: 300px;
            }
        }
    }
}

</style>
<template>
    <div class="page">
        <div class="page-header pd">
            <div></div>
            <sc-search :table="table" field="q" v-if="table"></sc-search>
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-image="{row}">
                <div class="image" v-if="row.attributes.bg_image">
                    <img :src="imageURL(row)"/>
                </div>
            </template>

            <template #column-clone="{row}">
                <sc-table-cell :table="table" field="clone" :padded="false" :clickable="false">
                    <sc-table-button @click="clone(row)" icon="copy">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete this Operational Message Template?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>

        </sc-table>

        <template-details v-if="showDetails" :item="showDetails" @close="showDetails = null;" type="Message"/>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
import TemplateDetails from "@/views/app/templates/components/template-details";

export default {
  name: "favourites",
  components: {TemplateDetails, ScSearch},

  data() {
    return {
      table: null,
      showDetails: null,
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `Favourite ${this.resource.plural}`,
      url: `templates`,
      fetchArguments: () => {
        return '&is_template=1'
      },
      columns: {
        image: {sort: false, width: 300},
        name: {
          target: 'attributes.title',
          fill: true,
          sort: false,
        },
        clone: {width: 70, sort: false,},
        // edit: {width: 70, sort: false,},
        delete: {width: 80, sort: false},
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`Message template deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit?tab=6`)
    },

    clone(row) {
      this.$router.push(`new/?tab=0&clone=${row.id}`)
    },

    imageURL(row) {
      return process.env.VUE_APP_AWS_URL + row.attributes.bg_image;
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.image {
    height: 4em;
    overflow: hidden;
    padding: 0.5em 0;

    img {
        height: 100%;
        object-fit: contain;
    }
}
</style>
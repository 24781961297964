var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header pd"},[_c('div'),(_vm.table)?_c('sc-search',{attrs:{"table":_vm.table,"field":"q"}}):_vm._e()],1),(_vm.table)?_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-image",fn:function(ref){
var row = ref.row;
return [(row.attributes.bg_image)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.imageURL(row)}})]):_vm._e()]}},{key:"column-clone",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"clone","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"copy"},on:{"click":function($event){return _vm.clone(row)}}})],1)]}},{key:"column-edit",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"edit","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.edit(row)}}})],1)]}},{key:"column-delete",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete this Operational Message Template? ")])],1)]}}],null,false,1998721972)}):_vm._e(),(_vm.showDetails)?_c('template-details',{attrs:{"item":_vm.showDetails,"type":"Message"},on:{"close":function($event){_vm.showDetails = null;}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }